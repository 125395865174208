import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../dispatch'
import { OfficeSimpleEvent, TextcutRectangle } from '../types'
import { popRedoStack, pushToUndoStack } from '../slice/undoSlice'
import { createBindingAndAddOnSelectionHandler } from '../workbook'
import { addMultipleReferences, addReferece } from '../slice/referenceSlice'
import { nanoid } from 'nanoid'

const getRedoRibbonRequestInput = (enabled: boolean) => ({
  tabs: [
    {
      id: 'QuickCut',
      groups: [{ id: 'DeleteGroup', controls: [{ id: 'Redo', enabled }] }],
    },
  ],
})

const recreateReference = (
  oldReference: TextcutRectangle
): TextcutRectangle => {
  const reference = oldReference
  const newReference: TextcutRectangle = {
    ...reference,
    bindingId: nanoid(),
  }
  return newReference
}

const useRedo = () => {
  const redos = useAppSelector((state) => state.undo.redoStack)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (redos.length) {
      Office.ribbon.requestUpdate(getRedoRibbonRequestInput(true))
    } else {
      Office.ribbon.requestUpdate(getRedoRibbonRequestInput(false))
    }
  }, [redos])

  useEffect(() => {
    const handler = async () => {
      const item = redos.at(-1)
      if (!item) return
      if (item.type === 'ADD') {
        await Excel.run(async (ctx) => {
          const sheet = ctx.workbook.worksheets.getItemOrNullObject(
            item.reference.sheetId
          )
          await ctx.sync()
          if (sheet.isNullObject) {
            dispatch(popRedoStack())
            return
          }

          if (item.references && item.references.length) {
            const range = sheet.getRange(item.reference.rangeAddr)
            range.values = item.reference.values
            range.format.fill.color = item.reference.cellColor
            range.format.autofitColumns()
            range.format.font.name = 'Segoe UI'

            const newReferences: TextcutRectangle[] = []
            for (const ref of item.references) {
              const newRef = recreateReference(ref)
              newReferences.push(newRef)
            }

            const first = newReferences[0]

            createBindingAndAddOnSelectionHandler(
              ctx,
              range,
              'Range',
              first.bindingId
            )
            await ctx.sync()
            await dispatch(addMultipleReferences(newReferences))
            dispatch(popRedoStack())
            const newItem = {
              ...item,
              reference: first,
              references: [...newReferences],
            }
            dispatch(pushToUndoStack(newItem))
          } else {
            const range = sheet.getRange(item.reference.rangeAddr)
            range.values = item.reference.values
            range.format.fill.color = item.reference.cellColor
            range.format.autofitColumns()
            range.format.font.name = 'Segoe UI'
            const newReference = recreateReference(item.reference)

            createBindingAndAddOnSelectionHandler(
              ctx,
              range,
              'Range',
              newReference.bindingId
            )
            await ctx.sync()
            await dispatch(addReferece(newReference))
            dispatch(popRedoStack())
            dispatch(pushToUndoStack({ ...item, reference: newReference }))
          }
        })
      }
      // else if (item.type === 'DELETE') {
      //   await Excel.run(async (ctx) => {
      //     const binding = ctx.workbook.bindings.getItemOrNullObject(
      //       item.reference.bindingId
      //     )
      //     const sheet = ctx.workbook.worksheets.getItemOrNullObject(
      //       item.reference.sheetId
      //     )
      //     await ctx.sync()
      //     if (binding.isNullObject || sheet.isNullObject) {
      //       dispatch(popRedoStack())
      //       return
      //     }

      //     const range = sheet.getRange(item.reference.rangeAddr)
      //     range.clear()

      //     binding.delete()
      //     await ctx.sync()

      //     await dispatch(
      //       deleteReference({
      //         sheetId: item.reference.sheetId,
      //         rangeAddress: item.reference.rangeAddr,
      //       })
      //     )
      //     dispatch(pushToUndoStack(item))
      //   })
      // }
    }

    window.addEventListener(OfficeSimpleEvent.REDO, handler)

    return () => window.removeEventListener(OfficeSimpleEvent.REDO, handler)
  }, [redos, dispatch])
}

export default useRedo
