import { useEffect } from 'react'
import { AppCustomEvent } from '../types'
import { useAppDispatch, useAppSelector } from '../dispatch'
import { useNavigate } from 'react-router-dom'
import { setShowSelectionOnlyRange } from '../slice/showSelectionOnlySlice'

const useOnReferenceSelected = () => {
  const references = useAppSelector((state) => state.references.references)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handler = (event: any) => {
      const e = event as unknown as CustomEvent
      const bidingId = e?.detail?.bindingId ?? ''
      const item = references.find((ref) => ref.bindingId === bidingId)
      if (item) {
        if (item.bindingGroupId) {
          dispatch(
            setShowSelectionOnlyRange({
              rangeAddress: item.rangeAddr,
              sheetId: item.sheetId,
            })
          )
          const e = new CustomEvent(
            AppCustomEvent.SHOW_DUPLICATE_FINDINGS_POP_UP,
            {
              detail: { bindingGroupId: item.bindingGroupId },
            }
          )
          window.dispatchEvent(e)
        } else {
          dispatch(
            setShowSelectionOnlyRange({
              rangeAddress: item.rangeAddr,
              sheetId: item.sheetId,
            })
          )
          navigate(`/file/${item.fileId}`, {
            state: { page: item.filePage },
          })
        }
      }
    }

    window.addEventListener(AppCustomEvent.ON_REFERENCE_SELECTED, handler)

    return () =>
      window.removeEventListener(AppCustomEvent.ON_REFERENCE_SELECTED, handler)
  }, [references, navigate, dispatch])
}

export default useOnReferenceSelected
