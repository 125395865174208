import { Outlet, Route, Routes, useNavigate, Navigate } from 'react-router-dom'
import './App.css'
import FilesContainer from './components/FilesContainer'
import FileContainer from './components/FileContainer'
import DataMatching from './components/DataMatching'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useAppDispatch, useAppSelector } from './dispatch'
import {
  deleteReferencesWithBindingIds,
  initializeReferencesThunk,
  resetReferences,
} from './slice/referenceSlice'
import useOnActivated from './hooks/useOnActivated'
import { initActiveWorksheet } from './slice/activeWorksheetSlice'
import useOnActivatedReceiver from './hooks/useOnActivatedReceiver'
import { initSaveToLocal } from './slice/saveToLocalSlice'
import useUndo from './hooks/useUndo'
import { initComments, resetComments } from './slice/commentSlice'
import { checkSubscriptionStatus } from './api'
import { UnsubscribedPage } from './components/UnauthPages/UnsubscribedPage'
import useOnReferenceSelected from './hooks/useOnReferenceSelected'
import useOnChanged from './hooks/useOnChanged'
import useOnChangedReceiver from './hooks/useOnChangedReceiver'
import { BeatLoader } from 'react-spinners'
import ErrorMsg from './components/ErrorMsg'
import {
  FETCH_IS_SUBSCRIBED_ERROR_CONTENT,
  FETCH_IS_SUBSCRIBED_ERROR_TITLE,
} from './constant'
import useOnSelectionChange from './hooks/useOnSelectionChange'
import useBindingOnDataChangeEventReceiver from './hooks/useBindingOnDataChange'
import { initUserEmail } from './slice/userEmailSlice'
import { AnimatePresence } from 'framer-motion'
import useRedo from './hooks/useRedo'
import { revalidateFiles } from './utils/db'
import Popup from './components/Popup'
import DuplicateRadioGroup from './components/DuplicateFindingRadioGroup'
import { AppCustomEvent, TextcutRectangle } from './types'

// const queryClient = new QueryClient()

declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS: any
  }
}

type AppProps = {
  username: string
  userId: string
  userEmail: string
}

const LocationProvider = ({ children }: { children: ReactNode }) => (
  <AnimatePresence>{children}</AnimatePresence>
)

const RouteWithAnimation = () => {
  return (
    <Routes>
      <Route path="/file/:id" element={<FileContainer />} />
      <Route path="/files" element={<FilesContainer />} />
      {/* <Route path="/upload" element={<UploadContainer />} /> */}
      <Route path="/data-match" element={<DataMatching />} />
      <Route path="/" element={<FilesContainer />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

function MainComponent() {
  const [openPopup, setOpenPopup] = useState(false)
  const [referenceGroup, setReferenceGroup] = useState<TextcutRectangle[]>([])
  const [selected, setSelected] = useState('')
  const navigationRef = useRef(useNavigate())
  const dispatch = useAppDispatch()
  const references = useAppSelector((state) => state.references.references)
  const navigate = useNavigate()
  /**onSelection hooks */
  useOnSelectionChange()
  /**end of onSelection hooks */

  useOnReferenceSelected()
  useOnChanged()
  useOnChangedReceiver()
  useBindingOnDataChangeEventReceiver()

  useOnActivated()
  useOnActivatedReceiver()
  useUndo()
  useRedo()

  useEffect(() => {
    const promises = [
      dispatch(initializeReferencesThunk()),
      dispatch(initActiveWorksheet()),
      dispatch(initSaveToLocal()),
      dispatch(initComments()),
      dispatch(initUserEmail()),
      // dispatch(resetComments()),
      revalidateFiles(7),
    ]
    Promise.all(promises).catch((err) => console.error(err))
  }, [dispatch])

  /**Navigation effect */
  useEffect(() => {
    const navigationEventHandler = (event: any) => {
      const url = event?.detail?.url
      if (url) {
        Office.addin
          .showAsTaskpane()
          .then(() =>
            navigationRef.current(url === window.location.pathname ? '/' : url)
          )
      }
    }

    window.addEventListener('Navigation', navigationEventHandler)
    return () =>
      window.removeEventListener('Navigation', navigationEventHandler)
  }, [])

  useEffect(() => {
    const handler = (event: any) =>
      Excel.run(async (ctx) => {
        try {
          const e = event as unknown as CustomEvent
          const { sheetId, rangeAddress } = e.detail as unknown as {
            sheetId: string
            rangeAddress: string
          }
          const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
          sheet.load(['isNullObject'])
          await ctx.sync()
          if (sheet.isNullObject) return
          const range = sheet.getRange(rangeAddress)
          const filtered = references.filter((ref) => ref.sheetId === sheetId)
          const filteredMap = new Map<string, TextcutRectangle>()
          filtered.forEach((ref) => filteredMap.set(ref.bindingId, ref))
          const intersectedMap = new Map<string, Excel.Range>()
          filtered.forEach((ref) => {
            const r = range.getIntersectionOrNullObject(ref.rangeAddr)
            r.load(['isNullObject'])
            intersectedMap.set(ref.bindingId, r)
          })
          await ctx.sync()
          const bindingMap = new Map<string, Excel.Binding>()
          for (const [k, v] of intersectedMap) {
            if (v.isNullObject) continue
            const bindingItem = ctx.workbook.bindings.getItemOrNullObject(k)
            bindingItem.load(['isNullObject'])
            bindingMap.set(k, bindingItem)
          }
          await ctx.sync()
          const toBeDeleteIds = new Set<string>()
          for (const [k, v] of bindingMap) {
            if (v.isNullObject) continue
            v.delete()
            const item = filteredMap.get(k)
            if (!item) continue
            if (!item.bindingGroupId) toBeDeleteIds.add(item.bindingId)
            else {
              filtered
                .filter((elem) => elem.bindingGroupId === item.bindingGroupId)
                .forEach((elem) => {
                  toBeDeleteIds.add(elem.bindingId)
                })
            }
          }
          await dispatch(deleteReferencesWithBindingIds([...toBeDeleteIds]))
          await ctx.sync()
        } catch (err) {
          console.error(err)
        }
      })

    window.addEventListener('ReferenceDeletion', handler)

    return () => window.removeEventListener('ReferenceDeletion', handler)
  }, [dispatch, references])

  useEffect(() => {
    const handler = () => {
      dispatch(resetReferences())
        .then(() => dispatch(resetComments()))
        .then(() =>
          Excel.run(async (ctx) => {
            const bindings = ctx.workbook.bindings
            bindings.load('items')
            await ctx.sync()
            const items = bindings.items
            for (const item of items) item.delete()
          })
        )
        .catch((err) => console.error(err))
    }

    window.addEventListener('DELETE_ALL_REFERENCES', handler)

    return () => window.removeEventListener('DELETE_ALL_REFERENCES', handler)
  }, [dispatch])

  useEffect(() => {
    const handler = (e: any) => {
      if ('detail' in e && 'bindingGroupId' in e.detail) {
        const rects = references.filter(
          (re) => re.bindingGroupId === e.detail.bindingGroupId
        )
        const set = new Set<string>()
        rects.forEach((rect) => set.add(`${rect.fileId}::${rect.filePage}`))
        if (rects.length > 0 && set.size > 1) {
          setReferenceGroup(rects)
          setOpenPopup(true)
        } else if (rects.length > 0 && set.size === 1) {
          navigate(`/file/${rects[0].fileId}`, {
            state: { page: rects[0].filePage },
          })
        }
      }
    }

    window.addEventListener(
      AppCustomEvent.SHOW_DUPLICATE_FINDINGS_POP_UP,
      handler
    )

    return () =>
      window.removeEventListener(
        AppCustomEvent.SHOW_DUPLICATE_FINDINGS_POP_UP,
        handler
      )
  }, [references, navigate])

  return (
    <>
      <div className="flex flex-col w-full h-screen bg-[#f5f5f5] overflow-hidden">
        <Outlet />

        {/* <Routes>
          <Route path="/file/:id" element={<FileContainer />} />
          <Route path="/files" element={<FilesContainer />} />
          <Route path="/upload" element={<UploadContainer />} />
          <Route path="/data-match" element={<DataMatching />} />
          <Route path="/" element={<FilesContainer />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes> */}
        {openPopup && (
          <div className="w-72 flex">
            <Popup
              isOpen={openPopup}
              setIsOpen={setOpenPopup}
              popupHeader={'Duplicate Findings'}
              popupContent={
                <DuplicateRadioGroup
                  rects={referenceGroup}
                  selected={selected}
                  setSelected={setSelected}
                />
              }
              firstBtn={{
                text: 'View',
                onClick: () => {
                  if (selected !== '') {
                    const rect = referenceGroup.find(
                      (re) => re.bindingId === selected
                    )
                    if (rect) {
                      navigate(`/file/${rect.fileId}`, {
                        state: { page: rect.filePage },
                      })
                    }
                  }
                  setOpenPopup(false)
                },
              }}
              secondBtn={{
                text: 'Cancel',
                onClick: () => {
                  setOpenPopup(false)
                  setReferenceGroup([])
                },
              }}
            />
          </div>
        )}
        <LocationProvider>
          <RouteWithAnimation />
        </LocationProvider>
      </div>
    </>
  )
}
/**
 * MsalProvider is a wrapper component that provides the PublicClientApplication instance to the rest of the application.
 * This allows the application to use the instance for login, logout, and token acquisition.
 *
 * @returns MsalProvider component with the PublicClientApplication instance
 */
function App({ userEmail, userId, username }: AppProps) {
  const { data, isError, isLoading } = useQuery(
    [userId, userEmail],
    checkSubscriptionStatus,
    { refetchOnWindowFocus: false }
  )

  // Feedback feature via ribbon button click and auto populating user info
  useEffect(() => {
    const handler = () => {
      const elem = document.getElementById('atlwdg-trigger')
      const issueCollector = document.getElementById('atlwdg-frame')
      if (!issueCollector) elem?.click()
    }

    window.addEventListener('OPEN_FEEDBACK', handler)

    return () => window.removeEventListener('OPEN_FEEDBACK', handler)
  }, [userEmail, username])

  if (isError)
    return (
      <div className="w-full h-full p-4 flex">
        <ErrorMsg
          title={FETCH_IS_SUBSCRIBED_ERROR_TITLE}
          content={FETCH_IS_SUBSCRIBED_ERROR_CONTENT}
        />
      </div>
    )

  if (isLoading)
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <BeatLoader size={20} color="#36d7b7" />
      </div>
    )

  return (
    <>
      {data && data.isSubscribed && <MainComponent />}
      {(!data || !data?.isSubscribed) && <UnsubscribedPage />}
    </>
  )
}

export default App
