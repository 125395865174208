import {
  VirtualizerOptions,
  elementScroll,
  useVirtualizer,
} from '@tanstack/react-virtual'
import { PageViewport } from 'pdfjs-dist/types/src/display/display_utils'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Document } from 'react-pdf'
import { HighlightArea, ReaderProps } from './types'
import Page from './Page'
import usePageObserver from './usePageObserver'
import useRotation from './useRotation'
import useVirtualizerVelocity from './useVirtualizerVelocity'
import useZoom from './useZoom'
import { easeOutQuint, getOffsetForHighlight } from './util'
import useWheel from './useWheel'

export const EXTRA_HEIGHT = 10
export const RESERVE_WIDTH = 50
export const DEFAULT_HEIGHT = 600

const determineScale = (parentElement: HTMLElement, width: number): number => {
  const scaleWidth = (parentElement.clientWidth - RESERVE_WIDTH) / width
  return scaleWidth
}

const Reader = ({
  file,
  initialScale,
  initialRotation = 0,
  loading,
  onPageChange,
  onDocumentLoad,
  onViewportsMeasured,
  setReaderAPI,
  classes,
  reactPDFDocumentProps,
  virtualizerOptions = { overscan: 0 },
  renderKonvaComponent,
  onPageLoadSuccess,
  onPageRenderSuccess,
  onPageLoadError,
  onPageRenderError,
}: ReaderProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const scrollingRef = useRef<number | null>(null)
  const [numPages, setNumPages] = useState<number>(0)
  const [viewports, setPageViewports] = useState<Array<PageViewport> | null>(
    null
  )
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null)
  const [scale, setScale] = useState<number | undefined>(initialScale)
  const [defaultScale, setDefaultScale] = useState<number | null>(null)
  const [rotation, setRotation] = useState<number>(initialRotation)
  const [defaultRotations, setDefaultRotations] = useState<number[] | null>()

  const [currentPage, setCurrentPage] = useState<number | null>(null)
  const [viewportsReady, setViewportsReady] = useState<boolean>(false)

  const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] = useCallback(
    (offset, canSmooth, instance) => {
      const duration = 400
      const start = parentRef.current?.scrollTop || 0
      const startTime = (scrollingRef.current = Date.now())
      // setIsSystemScrolling(true);

      const run = () => {
        if (scrollingRef.current !== startTime) return
        const now = Date.now()
        const elapsed = now - startTime
        const progress = easeOutQuint(Math.min(elapsed / duration, 1))
        const interpolated = start + (offset - start) * progress

        if (elapsed < duration) {
          elementScroll(interpolated, canSmooth, instance)
          requestAnimationFrame(run)
        } else {
          elementScroll(interpolated, canSmooth, instance)
          // setIsSystemScrolling(false);
        }
      }

      requestAnimationFrame(run)
    },
    [parentRef]
  )

  const { increaseZoom, decreaseZoom, zoomFitWidth } = useZoom({
    scale,
    defaultScale,
    setScale,
  })
  const { rotateClockwise, rotateCounterClockwise } = useRotation({
    rotation,
    setRotation,
  })

  useWheel(parentRef, scale, setScale)

  const onDocumentLoadSuccess = async (newPdf: PDFDocumentProxy) => {
    setPdf(newPdf)
    setNumPages(newPdf.numPages)

    // user defined callback
    onDocumentLoad && onDocumentLoad(newPdf)
  }

  const getRotationAdjustment = useCallback(
    (index: number) => {
      const defaultRotation = (defaultRotations && defaultRotations[index]) || 0

      return defaultRotation
    },
    [defaultRotations]
  )

  const estimateSize = useCallback(
    (index: number) => {
      if (!viewports || !viewports[index]) return DEFAULT_HEIGHT
      return viewports[index].height + EXTRA_HEIGHT
    },
    [viewports]
  )

  const virtualizer = useVirtualizer({
    count: numPages || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: estimateSize,
    overscan: virtualizerOptions?.overscan ?? 0,
    scrollToFn,
  })

  const { pageObserver } = usePageObserver({
    parentRef,
    setCurrentPage,
    numPages,
  })

  useEffect(() => {
    const calculateViewports = async () => {
      if (!pdf || scale === undefined) return

      const viewports = await Promise.all(
        Array.from({ length: pdf.numPages }, async (_, index) => {
          const page = await pdf.getPage(index + 1)
          const deltaRotate = page.rotate || 0
          const viewport = page.getViewport({
            scale: scale,
            rotation: rotation + deltaRotate,
            // rotation,
          })
          return viewport
        })
      )

      const rotations = await Promise.all(
        Array.from({ length: pdf.numPages }, async (_, index) => {
          const page = await pdf.getPage(index + 1)
          return page.rotate || 0 // Default to 0 if no rotation metadata
        })
      )
      setDefaultRotations(rotations)
      setPageViewports(viewports)
      setViewportsReady(true)
    }

    setViewportsReady(false)
    calculateViewports()
  }, [pdf, scale, rotation])

  useEffect(() => {
    if (!pdf) return
    const fetchPageAndSetScale = async ({
      initialScale,
    }: {
      initialScale: number | undefined
    }) => {
      const firstPage = await pdf.getPage(1)
      const firstPageDefaultRotation = firstPage.rotate || 0
      const firstViewPort = firstPage.getViewport({
        scale: 1,
        rotation: firstPageDefaultRotation,
      })
      const newScale = determineScale(parentRef.current!, firstViewPort.width)
      if (!initialScale) setScale(newScale)
      if (initialScale) setScale(initialScale)
      setDefaultScale(newScale)
    }

    fetchPageAndSetScale({ initialScale })
  }, [pdf, initialScale, initialRotation])

  useEffect(() => {
    if (!currentPage) return
    onPageChange && pdf && onPageChange({ currentPage, doc: pdf })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  useEffect(() => {
    if (!viewports || !viewportsReady) return
    if (scale === undefined) return
    virtualizer.measure()
    onViewportsMeasured && onViewportsMeasured()

    const jumpToPage = (pageIndex: number) => {
      virtualizer.scrollToIndex(pageIndex, {
        align: 'start',
        // behavior: "smooth",
      })
    }

    const jumpToOffset = (offset: number) => {
      virtualizer.scrollToOffset(offset, {
        align: 'start',
        behavior: 'smooth',
      })
    }

    const jumpToHighlightArea = (area: HighlightArea) => {
      const startOffset = virtualizer.getOffsetForIndex(
        area.pageIndex,
        'start'
      )[0]
      const itemHeight = estimateSize(area.pageIndex)
      const offset = getOffsetForHighlight({
        ...area,
        rotation,
        itemHeight: itemHeight - 10, // accounts for padding top and bottom
        startOffset: startOffset - 5, // accounts for padding on top
      })

      virtualizer.scrollToOffset(offset, {
        align: 'start',
        // behavior: "smooth",
      })
    }

    setReaderAPI &&
      setReaderAPI({
        jumpToPage,
        jumpToHighlightArea,
        jumpToOffset,
        increaseZoom,
        decreaseZoom,
        zoomFitWidth,
        rotateClockwise,
        rotateCounterClockwise,
        scale,
        rotation,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewports, scale, viewportsReady])

  const { normalizedVelocity } = useVirtualizerVelocity({
    virtualizer,
    estimateSize,
  })

  const isScrollingFast = Math.abs(normalizedVelocity) > 1
  const shouldRender = !isScrollingFast

  return (
    <div id="reader" ref={parentRef} className="h-full w-full overflow-auto">
      <Document
        {...reactPDFDocumentProps}
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={loading}
      >
        <div
          id="reader-container"
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            position: 'relative',
          }}
          className={`${classes?.pagesContainer}`}
        >
          {pdf ? (
            virtualizer
              .getVirtualItems()
              .map((virtualItem) => (
                <Page
                  key={virtualItem.key}
                  virtualItem={virtualItem}
                  viewports={viewports}
                  scale={scale}
                  rotationAdjustment={getRotationAdjustment(virtualItem.index)}
                  rotation={rotation + getRotationAdjustment(virtualItem.index)}
                  pageObserver={pageObserver}
                  shouldRender={shouldRender}
                  classes={classes}
                  loading={loading}
                  renderKonvaComponent={renderKonvaComponent}
                  onLoadSuccess={onPageLoadSuccess}
                  onRenderSuccess={onPageRenderSuccess}
                  onLoadError={onPageLoadError}
                  onRenderError={onPageRenderError}
                />
              ))
          ) : (
            <div />
          )}
        </div>
      </Document>
    </div>
  )
}
export default Reader
