import FileController from '../FileContrller'
import PDFDisplaySection from '../PDFDisplaySection'
import { useEffect, useReducer, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { FileRotation, RotationType } from '../../types'
import { isNaN } from 'lodash'
import useFile from '../../hooks/useFile'
import ErrorMsg from '../ErrorMsg'
import { FETCH_FILE_ERR_TITLE, GENERAL_ERR_CONTENT } from '../../constant'
import { ReaderAPI } from '../PdfViewer/types'
import { ToastContainer } from 'react-toastify'

const rotationReducer = (
  state: RotationType,
  action: { type: string; payload: undefined | number }
): RotationType => {
  if (action.type === FileRotation.Rotation) {
    const val = (state.curr + 90) % 360
    return {
      ...state,
      curr: val,
    }
  } else if (action.type === FileRotation.SET_META) {
    return {
      ...state,
      meta: action.payload ?? 0,
    }
  }

  return state
}

const FileContainer = () => {
  const [fileId, setFileId] = useState('')
  const [page, setPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState(1)
  const divRef = useRef<HTMLDivElement>(null)
  const [displayKonva, setDisplayKonva] = useState(false)
  const { id } = useParams()
  const [degree, dispatchRotation] = useReducer(rotationReducer, {
    curr: 0,
    meta: 0,
  })
  const [showConfirmRedact, setShowConfirmRedact] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const location = useLocation()
  const [searchAllItem, setSearchAllItem] = useState<string[]>([])
  const navigate = useNavigate()
  const [readerAPI, setReaderAPI] = useState<ReaderAPI | null>(null)
  const { isError, ocr, pdf, extractionMethod, isLocalFile } = useFile(fileId)
  useEffect(() => {
    const locationPageNum = parseInt(location.state?.page)
    console.log('locationPageNum:', locationPageNum)
    if (isNaN(locationPageNum)) return
    else {
      console.log(
        locationPageNum,
        totalPages,
        locationPageNum >= 1 && locationPageNum <= totalPages
      )
      if (locationPageNum >= 1 && locationPageNum <= totalPages) {
        setPage(locationPageNum)
        readerAPI?.jumpToPage(locationPageNum - 1)
      } else setPage(1)
    }
  }, [location, totalPages, readerAPI])

  useEffect(() => {
    setDisplayKonva(false)
    setFileId(id ?? '')
  }, [id])

  const publishConfirmRedactEvent = () => {
    const event = new CustomEvent('CONFIRM_REDACT', {})
    window.dispatchEvent(event)
  }

  if (isError) {
    return (
      <div className="flex p-4">
        {' '}
        <ErrorMsg
          title={FETCH_FILE_ERR_TITLE}
          content={GENERAL_ERR_CONTENT}
          navigate={navigate}
        />
      </div>
    )
  }

  if (pageLoading) {
    return (
      <div className="flex flex-col h-screen w-screen p-2 items-center justify-center">
        <BeatLoader color="#36d7b7" />
      </div>
    )
  }
  return (
    <div
      ref={divRef}
      className="flex flex-col h-full w-screen p-2 pt-0 overflow-y-hidden bg-[#F5F5F5]"
    >
      <ToastContainer position="top-center" />
      {pdf === '' && (
        <div className="flex flex-col h-screen w-screen p-2 items-center justify-center">
          <BeatLoader color="#36d7b7" />
        </div>
      )}
      {pdf !== '' && (
        <>
          <div className="sticky  top-0 right-0 z-50 w-full bg-[#F5F5F5]">
            <FileController
              totalPages={totalPages}
              currentPage={page}
              setCurrentPage={setPage}
              setDisplayKonva={setDisplayKonva}
              dispatchRotation={dispatchRotation}
              degree={degree}
              fileId={fileId}
              setSearchAllItem={setSearchAllItem}
              setFileId={setFileId}
              readerAPI={readerAPI}
            />
          </div>
          <div>
            <PDFDisplaySection
              file={pdf}
              page={page}
              divRef={divRef}
              setTotalPages={setTotalPages}
              displayKonva={displayKonva}
              setDisplayKonva={setDisplayKonva}
              setCurrentPage={setPage}
              ocr={ocr}
              fileId={fileId}
              extractionMethod={extractionMethod}
              rotationDegree={degree}
              dispatchRotation={dispatchRotation}
              setShowConfirmRedact={setShowConfirmRedact}
              setPageLoading={setPageLoading}
              searchAllItem={searchAllItem}
              totalPages={totalPages}
              navigate={navigate}
              setReaderAPI={setReaderAPI}
            />
          </div>
          {showConfirmRedact && !isLocalFile && (
            <div className="sticky bottom-0 left-3 z-50 w-full">
              <button
                className="bg-[#107C41] hover:bg-green-800 text-white font-bold py-2 px-4 rounded m-1 mr-3"
                onClick={publishConfirmRedactEvent}
              >
                Confirm Redact
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FileContainer
