import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import { useAppDispatch } from '../../dispatch'
import {
  deleteReferencesAndClearContentWithBindingId,
  deleteReferencesWithBindingId,
} from '../../slice/referenceSlice'
import { Cut } from '../../types'

type Props = {
  bindingId: string
  changeRulingDirection?: (bindingId: string, value: 0 | 1 | 2) => void
  dispatch?: ReturnType<typeof useAppDispatch>
  handleTableOptionsClick?: () => Promise<void>
  snipTable?: (tableId: string, type: Cut) => void
  removeSystemTable?: (table: string) => void
  extractAllSystemTables?: () => Promise<void>
  rulingDirection?: 0 | 1 | 2
}

const TableDropdown = ({
  bindingId,
  changeRulingDirection,
  dispatch,
  handleTableOptionsClick,
  snipTable,
  removeSystemTable,
  extractAllSystemTables,
  rulingDirection,
}: Props) => {
  const deleteReference = async () => {
    if (!dispatch) return
    try {
      await dispatch(deleteReferencesWithBindingId(bindingId))
    } catch (error) {
      console.error(
        `deleteReference with id ${bindingId} in TableDropdown, err=${error}`
      )
    }
  }

  const deleteReferenceAndClearContent = async () => {
    try {
      if (!dispatch) return
      await dispatch(deleteReferencesAndClearContentWithBindingId(bindingId))
    } catch (error) {
      console.error(
        `deleteReferenceAndClearContent with id ${bindingId} in TableDropdown, err=${error}`
      )
    }
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md  text-sm font-medium">
          <TuneOutlinedIcon sx={{ fontSize: 16 }} />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-50 right-0 mt-2 w-max origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          {changeRulingDirection && (
            <div className="px-1 py-1 z-20">
              {rulingDirection !== 1 && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100 ' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => changeRulingDirection(bindingId, 1)}
                    >
                      Add / remove rows
                    </button>
                  )}
                </Menu.Item>
              )}
              {rulingDirection !== 2 && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100 ' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => changeRulingDirection(bindingId, 2)}
                    >
                      Add / remove columns
                    </button>
                  )}
                </Menu.Item>
              )}
              {rulingDirection !== 0 && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100 ' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => changeRulingDirection(bindingId, 0)}
                    >
                      Exit edit
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          )}
          {(snipTable || extractAllSystemTables) && (
            <div className="px-1 py-1">
              {snipTable && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100 ' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => snipTable(bindingId, Cut.TABLECUT)}
                    >
                      Extract table
                    </button>
                  )}
                </Menu.Item>
              )}
              {snipTable && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100 ' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => snipTable(bindingId, Cut.TABLES)}
                    >
                      Extract repeating tables
                    </button>
                  )}
                </Menu.Item>
              )}
              {extractAllSystemTables && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100 ' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={extractAllSystemTables}
                    >
                      Extract all tables
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          )}
          {handleTableOptionsClick && (
            <div className="px-1 py-1 z-20">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-green-100 ' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={handleTableOptionsClick}
                  >
                    Advanced table formatting
                  </button>
                )}
              </Menu.Item>
            </div>
          )}
          <div className="px-1 py-1 z-20">
            {removeSystemTable && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'bg-green-100 ' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={() => removeSystemTable(bindingId)}
                  >
                    Delete system table
                  </button>
                )}
              </Menu.Item>
            )}
            {dispatch && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100 ' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => deleteReference()}
                    >
                      Delete table and keep data
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? 'bg-green-100' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={() => deleteReferenceAndClearContent()}
                    >
                      Delete table and remove data
                    </button>
                  )}
                </Menu.Item>
              </>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default TableDropdown
