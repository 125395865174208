import { KeyboardEvent, useEffect, useState } from 'react'

const useControl = () => {
  const [isHoldingCtrl, setIsHolderCtrl] = useState(false)

  useEffect(() => {
    const keyDown = (e: any) => {
      console.log(e)
      const ev = e as KeyboardEvent
      if (ev.key === 'Control' && !isHoldingCtrl) {
        ev.preventDefault()
        console.log('ctrl key down')
        setIsHolderCtrl(true)
      }
    }

    const keyUp = (e: any) => {
      const ev = e as KeyboardEvent
      if (ev.key === 'Control' && isHoldingCtrl) {
        ev.preventDefault()
        setIsHolderCtrl(false)
      }
    }

    window.addEventListener('keydown', keyDown)
    window.addEventListener('keyup', keyUp)

    return () => {
      window.removeEventListener('keydown', keyDown)
      window.removeEventListener('keyup', keyUp)
    }
  }, [isHoldingCtrl])

  return isHoldingCtrl
}

export default useControl
