import {
  CutTag,
  TableOptions,
  TextcutRectangle,
  UserGeneratedLine,
  Vertex,
} from '../types'
import { isTextcutRect } from './guards'
class TextcutRectangleBuilder {
  private builder: Partial<TextcutRectangle>

  constructor() {
    this.builder = {}
  }

  addX(x: number) {
    this.builder.x = x
    return this
  }

  addY(y: number) {
    this.builder.y = y
    return this
  }

  addW(w: number) {
    this.builder.w = w
    return this
  }

  addH(h: number) {
    this.builder.h = h
    return this
  }

  addBoudingInfo({
    x,
    h,
    w,
    y,
  }: {
    x: number
    y: number
    w: number
    h: number
  }) {
    this.addX(x)
    this.addY(y)
    this.addH(h)
    this.addW(w)
    return this
  }

  addFileId(id: string) {
    this.builder.fileId = id
    return this
  }

  addFilePage(page: number) {
    this.builder.filePage = page
    return this
  }

  addFileInfo({ fileId, filePage }: { fileId: string; filePage: number }) {
    this.addFileId(fileId)
    this.addFilePage(filePage)
    return this
  }

  addSheetName(name: string) {
    this.builder.sheetName = name
    return this
  }

  addSheetId(id: string) {
    this.builder.sheetId = id
    return this
  }

  addSheetRange(range: string) {
    this.builder.rangeAddr = range
    return this
  }

  addSheetInfo({
    range,
    sheetId,
    sheetName,
  }: {
    sheetName: string
    sheetId: string
    range: string
  }) {
    this.addSheetRange(range)
    this.addSheetName(sheetName)
    this.addSheetId(sheetId)
    return this
  }

  addDegee(degree: number) {
    this.builder.degree = degree
    return this
  }

  addOcrHeight(height: number) {
    this.builder.ocrH = height
    return this
  }

  addOcrWidth(width: number) {
    this.builder.ocrW = width
    return this
  }

  addOcrId(id: string) {
    this.builder.ocrId = id
    return this
  }

  addOcrInfo({
    height,
    id,
    width,
  }: {
    id: string
    width: number
    height: number
  }) {
    this.addOcrHeight(height)
    this.addOcrId(id)
    this.addOcrWidth(width)
    return this
  }

  addTag(tag: CutTag) {
    this.builder.tag = tag
    return this
  }

  addBindingId(bindingId: string) {
    this.builder.bindingId = bindingId
    return this
  }

  addBoudingPoly(vertices: Vertex[]) {
    this.builder.boundingPoly = { vertices }
    return this
  }

  addStyle({
    cellColor,
    fill,
    stroke,
  }: {
    fill: string
    stroke: string
    cellColor: string
  }) {
    this.builder.cellColor = cellColor
    this.builder.fill = fill
    this.builder.stroke = stroke
    return this
  }

  addValues(values: any[][]) {
    this.builder.values = values
    return this
  }

  addLines(lines: UserGeneratedLine[]) {
    this.builder.lines = lines
    return this
  }

  addBindingGroupId(groupId: string) {
    this.builder.bindingGroupId = groupId
    return this
  }

  addFileName(fileName: string) {
    this.builder.fileName = fileName
    return this
  }

  addTableOption(options: TableOptions | undefined) {
    if (options) {
      this.builder.tableOptions = { ...options }
    }
    return this
  }

  clear() {
    this.builder = {}
    return this
  }

  build(): TextcutRectangle | null {
    const elem = { ...this.builder }
    if (isTextcutRect(elem)) return elem
    return null
  }
}

export default TextcutRectangleBuilder
